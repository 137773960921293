import React, { useEffect, useRef, useCallback } from "react";
import { useStore, StoreState, Metric } from "../store";
import mapboxgl, { LngLatLike } from "mapbox-gl";
import { scoreColor, getCentroid } from "../utils";
import "mapbox-gl/dist/mapbox-gl.css";
import styled from "styled-components";
import { isValidCenter } from "../utils";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PUB_TOKEN || "";

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
`;

interface MapProps {
  customCenter?: LngLatLike;
  zoom?: number;
  customMapMetric?: Metric;
}

const Map: React.FC<MapProps> = ({
  customCenter = null,
  zoom = 3.7,
  customMapMetric = null,
}) => {
  const center = isValidCenter(customCenter) ? customCenter : [-97.4, 38];
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const hoveredStateId = useRef<number | null>(null);
  const {
    geojsonData,
    setActiveTab,
    mapMetric,
    setCurrentCSAData,
    geojsonCbsaData,
    setMapRef,
    isCbsaLevel,
  } = useStore((state: StoreState) => ({
    setActiveTab: state.setActiveTab,
    geojsonData: state.geojsonData,
    geojsonCbsaData: state.geojsonCbsaData,
    mapMetric: state.mapMetric,
    setCurrentCSAData: state.setCurrentCSAData,
    setMapRef: state.setMapRef,
    isCbsaLevel: state.isCbsaLevel,
  }));
  const effectiveMapMetric = customMapMetric || mapMetric;

  const selectedCbsaId = useRef<string | null>(null);

  const onCSAClick = useCallback(
    (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      const cbsaCode = e.features[0].properties.cBsaCode;
      const csaCode = e.features[0].properties.csaCode;
      const csaData = (
        cbsaCode
          ? geojsonCbsaData?.features.find(
            (g) => g.properties.cbsaCode === cbsaCode
          )
          : geojsonData?.features.find((g) => g.properties.csaCode === csaCode)
      )?.properties;

      setCurrentCSAData(csaData || null);
      setActiveTab("Inspect");

      const feature = e.features[0];
      const center = getCentroid(feature);

      mapRef?.current?.flyTo({
        center,
        zoom: 4.5,
        speed: 0.8,
        curve: 1,
      });
    },
    [geojsonData, geojsonCbsaData, setCurrentCSAData, setActiveTab, mapRef]
  );
  const onCbsaClickGetTracts = useCallback(
    async (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      const cbsaCode = e.features[0].properties.cbsaCode;
      if (cbsaCode !== selectedCbsaId.current) {
        selectedCbsaId.current = cbsaCode;
        try {
          const response = await fetch(`/api/tracts?cbsa=${cbsaCode}`);
          if (!response.ok) {
            throw new Error("Failed to fetch tracts");
          }
          const tractsData = (await response.json()).result;
          mapRef.current?.addSource(`tracts-${cbsaCode}`, {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: tractsData,
            },
          });
          mapRef.current?.addLayer({
            id: `tracts-layer-${cbsaCode}`,
            type: "line", // Change from 'fill' to 'line'
            source: `tracts-${cbsaCode}`,
            layout: {},
            paint: {
              "line-color": "#0000FF", // Set the color of the outline
              "line-width": 1, // Set the width of the outline
            },
          });
        } catch (error) {
          console.error("Error fetching tracts:", error);
        }
      }
    },
    [mapRef, selectedCbsaId]
  );

  const addInteractiveEvents = (map: mapboxgl.Map) => {
    ["csa", "cbsa"].forEach((objName) => {
      const layerId = `${objName}-shapes`;
      const sourceName = `${objName}s`;
      map.on("click", layerId, onCSAClick);
      if (objName === "cbsa" && process.env.REACT_APP_ENV !== "production") {
        map.on("click", layerId, onCbsaClickGetTracts);
      }
      map.on("mousemove", layerId, (e) => {
        if (e.features && e.features.length > 0) {
          if (
            hoveredStateId.current !== null &&
            hoveredStateId.current !== e.features[0].id
          ) {
            map.setFeatureState(
              { source: sourceName, id: hoveredStateId.current },
              { hover: false }
            );
          }

          hoveredStateId.current =
            typeof e.features[0].id === "number" ? e.features[0].id : null;
          if (hoveredStateId.current !== null) {
            map.setFeatureState(
              { source: sourceName, id: hoveredStateId.current },
              { hover: true }
            );
          }
          map.getCanvas().style.cursor = "pointer";
        }
      });
      map.on("mouseleave", layerId, () => {
        if (hoveredStateId.current !== null) {
          map.setFeatureState(
            { source: sourceName, id: hoveredStateId.current },
            { hover: false }
          );
        }

        hoveredStateId.current = null;
        map.getCanvas().style.cursor = "";
      });
    });
  };

  const getFillColor = (metric: Metric): mapboxgl.Expression => [
    "interpolate",
    ["linear"],
    [
      "get",
      metric === "Overall" ? "overall" : metric,
      ["object", ["get", "scoreTiers"]],
    ],
    0,
    scoreColor(0),
    0.25,
    scoreColor(0.25),
    0.5,
    scoreColor(0.5),
    0.75,
    scoreColor(0.75),
    1,
    scoreColor(1),
  ];

  const addLayersIfSourcesLoaded = () => {
    const map = mapRef.current;
    if (!map) return;
    if (!map.getSource("csas") && geojsonData) {
      console.log("adding csas source");
      map.addSource("csas", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features:
            geojsonData?.features.map((feature, index) => ({
              ...feature,
              type: "Feature",
              id: index,
            })) || [],
        },
      });
    }
    if (!map.getSource("cbsas") && geojsonCbsaData) {
      console.log("adding cbsas source");
      map.addSource("cbsas", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features:
            geojsonCbsaData?.features.map((feature, index) => ({
              ...feature,
              type: "Feature",
              id: index,
            })) || [],
        },
      });
    }
    if (map.getSource("csas") && !map.getLayer("csa-shapes")) {
      map.addLayer({
        id: "csa-shapes",
        type: "fill",
        source: "csas",
        layout: {},
        paint: {
          "fill-color": getFillColor(effectiveMapMetric),
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            0.9,
            0.7,
          ],
          "fill-outline-color": "rgba(255, 255, 255, 0.2)",
        },
      });
    }

    if (map.getSource("cbsas") && !map.getLayer("cbsa-shapes")) {
      map.addLayer({
        id: "cbsa-shapes",
        type: "fill",
        source: "cbsas",
        layout: {},
        paint: {
          "fill-color": getFillColor(mapMetric),
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            0.9,
            0.7,
          ],
          "fill-outline-color": "rgba(255, 255, 255, 0.2)",
        },
      });
    }

    updateLayerVisibility(map, isCbsaLevel);
    updateLayerPaintProperties(map, effectiveMapMetric);
    addInteractiveEvents(map);
  };

  useEffect(() => {
    if (!mapContainerRef.current) {
      return;
    }
    if (!mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/light-v11",
        projection: "mercator" as any,
        center: center as LngLatLike,
        zoom,
      });
      mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-right");
      setMapRef(mapRef.current);
    }
    const map = mapRef.current;
    map.on("styledata", addLayersIfSourcesLoaded);
    return () => {
      if (map) {
        map.off("styledata", addLayersIfSourcesLoaded);
      }
    };
  }, [geojsonData, geojsonCbsaData, center, zoom, setMapRef]);
  const updateLayerVisibility = (map: mapboxgl.Map, isCbsaLevel: boolean) => {
    if (map.getLayer("csa-shapes")) {
      map.setLayoutProperty(
        "csa-shapes",
        "visibility",
        isCbsaLevel ? "none" : "visible"
      );
    }
    if (map.getLayer("cbsa-shapes")) {
      map.setLayoutProperty(
        "cbsa-shapes",
        "visibility",
        isCbsaLevel ? "visible" : "none"
      );
    }
  };
  const updateLayerPaintProperties = (
    map: mapboxgl.Map,
    effectiveMapMetric: Metric
  ) => {
    if (map.getLayer("csa-shapes")) {
      map.setPaintProperty(
        "csa-shapes",
        "fill-color",
        getFillColor(effectiveMapMetric)
      );
    }
    if (map.getLayer("cbsa-shapes")) {
      map.setPaintProperty(
        "cbsa-shapes",
        "fill-color",
        getFillColor(effectiveMapMetric)
      );
    }
  };
  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;
    updateLayerVisibility(map, isCbsaLevel);
  }, [isCbsaLevel]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;
    updateLayerPaintProperties(map, effectiveMapMetric);
  }, [effectiveMapMetric]);

  return <MapContainer ref={mapContainerRef} />;
};

export default Map;
