import styled from "styled-components";

export const Title = styled.h2`
  font-size: 1.9rem;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  color: #4a4a4a;
`;

export const SmallTitleLight = styled.p`
  font-size: 1.1rem;
  font-weight: 400;
  font-family: "Barlow", sans-serif;
  color: #424242;
`;

export const SmallTitleNotBold = styled.p`
  font-size: 1.1rem;
  font-weight: 500;
  font-family: "Barlow", sans-serif;
  color: #424242;
`;

export const BodyTextLight = styled.span`
  font-size: 0.9rem;
  font-family: "Barlow", sans-serif;
  color: #4a4a4a;
  font-weight: 500;
`;

export const BodyTextBold = styled.span`
  font-size: 0.9rem;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  color: #4a4a4a;
`;

export const SmallTitle = styled.h3`
  font-size: 1.1rem;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
  color: #424242;
`;
export const SmallTitleBold = styled.h3`
  font-size: 1.1rem;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  color: #424242;
`;

export const BodyText = styled.p`
  font-size: 0.8rem;
  color: #4a4a4a;
`;

export const PageTitle = styled.h1`
  font-size: 1.5rem;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  color: #424242;
`;

export const LogoTitle = styled.h1`
  font-size: 1.125rem;
  font-family: "Barlow", sans-serif;
  font-weight: 700; /* Ensure the bold weight is applied */
  color: #424242;
`;
