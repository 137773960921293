import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Map from "../components/Map";
import { useApiRequest } from "../network";
import NavBar from "../components/NavBar";
import SidePanel from "../components/SidePanel";
import geojsonUrl from "../data/csas.geojson";
import geojsonCbsaUrl from "../data/cbsas.geojson";
import { useStore } from "../store";
import Dive from "./Dive";
import { getCentroid } from "../utils";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

interface PanelProps {
  isOpen: boolean;
  isWide?: boolean;
}

const SidePanelContainer = styled.div<PanelProps>`
  width: 330px;
  transition: transform 0.3s ease-in-out;
`;

const BottomPanel = styled.div<PanelProps>`
  position: fixed;
  bottom: 0;
  right: 0;
  height: ${(props) => (props.isOpen ? "calc(100vh - 60px)" : "80vh")};
  width: calc(100% - 316px);
  background-color: white;
  transform: translateY(${(props) => (props.isOpen ? "0" : "100%")});
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const MapContainer = styled.div<PanelProps>`
  flex-grow: 1;
  transition: width 0.3s ease-in-out;
  width: ${(props) => (props.isWide ? "100%" : "calc(100% - 300px)")};
`;

const CACHE_DURATION = 10 * 60 * 1000; // 10 minutes in milliseconds

export default function HomePage() {
  const { csaId } = useParams<{ csaId: string }>();
  const {
    geojsonData,
    setGeojsonData,
    exploreDataPageCsa,
    setConfig,
    setGeojsonCbsaData,
    geojsonCbsaData,
    mapRef,
    setExploreDataPage,
  } = useStore();
  const [isBottomPanelOpen, setBottomPanelOpen] = useState(false);

  const makeApiRequest = useApiRequest();

  useEffect(() => {
    const loadTieredConfig = async () => {
      // Check localStorage first
      const cachedConfig = localStorage.getItem('tieredConfig');
      const cachedTimestamp = localStorage.getItem('tieredConfigTimestamp');

      const now = Date.now();
      const shouldRefresh = !cachedTimestamp || now - parseInt(cachedTimestamp) > CACHE_DURATION;

      // Use cached data if available
      if (cachedConfig) {
        console.log('Using cached tiered config')
        setConfig(JSON.parse(cachedConfig));
      }

      // Refresh in background if needed
      if (shouldRefresh) {
        console.log('Refreshing tiered config...')
        makeApiRequest({
          method: "GET",
          endpoint: "/tiered-config",
          onSuccess: (data) => {
            setConfig(data);
            localStorage.setItem('tieredConfig', JSON.stringify(data));
            localStorage.setItem('tieredConfigTimestamp', now.toString());
            console.log("Tiered config refreshed");
          },
          hideSuccessSnackbar: true,
        });
      }
    };

    loadTieredConfig();
  }, [setConfig]);


  useEffect(() => {
    const loadGeoJsonData = async () => {
      // Check localStorage first
      const cachedData = localStorage.getItem('geojsonData');
      const cachedTimestamp = localStorage.getItem('geojsonDataTimestamp');
      const cachedCbsaData = localStorage.getItem('geojsonCbsaData');
      const cachedCbsaTimestamp = localStorage.getItem('geojsonCbsaTimestamp');

      const now = Date.now();
      const shouldRefreshCsa = !cachedTimestamp || now - parseInt(cachedTimestamp) > CACHE_DURATION;
      const shouldRefreshCbsa = !cachedCbsaTimestamp || now - parseInt(cachedCbsaTimestamp) > CACHE_DURATION;

      // Use cached data if available
      if (cachedData) {
        console.log('Using cached CSAs')
        setGeojsonData(JSON.parse(cachedData));
      }
      if (cachedCbsaData) {
        console.log('Using cached CBSAs')
        setGeojsonCbsaData(JSON.parse(cachedCbsaData));
      }

      // Refresh in background if needed
      if (shouldRefreshCsa) {
        console.log('Refreshing CSAs...')
        try {
          const response = await fetch(geojsonUrl);
          const data = await response.json();
          setGeojsonData(data);
          localStorage.setItem('geojsonData', JSON.stringify(data));
          localStorage.setItem('geojsonDataTimestamp', now.toString());
          console.log("GeoJSON data refreshed");
        } catch (error) {
          console.error("Failed to refresh GeoJSON data:", error);
        }
      }

      if (shouldRefreshCbsa) {
        console.log('Refreshing CBSAs...')
        try {
          const response = await fetch(geojsonCbsaUrl);
          const data = await response.json();
          setGeojsonCbsaData(data);
          localStorage.setItem('geojsonCbsaData', JSON.stringify(data));
          localStorage.setItem('geojsonCbsaTimestamp', now.toString());
          console.log("GeoJSON CBSA data refreshed");
        } catch (error) {
          console.error("Failed to refresh GeoJSON CBSA data:", error);
        }
      }
    };

    loadGeoJsonData();
  }, [setGeojsonData, setGeojsonCbsaData]);

  useEffect(() => {
    if (csaId && geojsonData) {
      const csaData = geojsonData.features.find(
        (feature) => feature.properties.csaCode === csaId
      )?.properties;
      if (csaData) {
        setExploreDataPage(csaData);
      }
    }
  }, [csaId, geojsonData, setExploreDataPage]);

  useEffect(() => {
    const open = !!exploreDataPageCsa;
    setBottomPanelOpen(open);
    if (open) {
      const ourFeature = geojsonData?.features.find(
        (feature) => feature.properties.csaCode === exploreDataPageCsa?.csaCode
      );
      if (mapRef && !!ourFeature) {
        const center = getCentroid(ourFeature);
        if (center.every((coord) => coord !== 0 && !isNaN(coord))) {
          mapRef.flyTo({
            center,
            zoom: 6,
            speed: 0.8,
            curve: 1,
          });
        }
      }
    }
  }, [!!exploreDataPageCsa, geojsonData, mapRef, getCentroid]);

  return (
    <PageContainer>
      <NavBar />
      <ContentContainer>
        <SidePanelContainer isOpen={true}>
          <SidePanel />
        </SidePanelContainer>
        <MapContainer isOpen={true} isWide={isBottomPanelOpen}>
          {!isBottomPanelOpen && geojsonData && geojsonCbsaData && <Map />}
        </MapContainer>
      </ContentContainer>
      <BottomPanel isOpen={isBottomPanelOpen}>
        <Dive />
      </BottomPanel>
    </PageContainer>
  );
}

