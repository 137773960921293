import React, { useState, useEffect } from "react";
import { useApiRequest } from "../network";
import { useStore } from "../store";
import styled from "styled-components";
import Slider from "./Slider";
import { LogoTitle, PageTitle } from "../text";
const NavBarContainer = styled.div`
  width: 100%;
  background-color: white;
  padding: 2px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid grey;
  max-height: 60px;
  min-height: 60px;
`;

const LogoImage = styled.img`
  height: 3rem; /* equivalent to h-12 */
  margin: 0 1rem; /* equivalent to mx-4 */
`;

const UserSection = styled.div`
  margin-right: 1rem; /* equivalent to mr-4 */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  font-size: 0.875rem; /* equivalent to text-sm */
  font-weight: medium;
  min-width: 200px;
`;

const Button = styled.button`
  background-color: #3182ce; /* equivalent to bg-blue-500 */
  &:hover {
    background-color: #2b6cb0; /* equivalent to hover:bg-blue-700 */
  }
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem; /* equivalent to py-2 px-4 */
  border-radius: 0.375rem; /* equivalent to rounded */
  transition: background-color 0.3s; /* equivalent to transition duration-300 */
`;

type User = {
  isLoggedIn: boolean;
  user?: string;
};

export default function NavBar() {
  const makeApiRequest = useApiRequest();
  const [user, setUser] = useState<User>({ isLoggedIn: false });
  const [isHovered, setIsHovered] = useState(false);
  const { isCbsaLevel, toggleLevel, exploreDataPageCsa } = useStore(
    (state) => ({
      isCbsaLevel: state.isCbsaLevel,
      toggleLevel: state.toggleLevel,
      exploreDataPageCsa: state.exploreDataPageCsa,
    })
  );
  useEffect(() => {
    makeApiRequest({
      method: "GET",
      endpoint: "/user",
      onSuccess: setUser,
      hideSuccessSnackbar: true,
    });
  }, []);

  const handleLogin = () => {
    window.location.href = "/api/login";
  };

  const handleLogout = () => {
    makeApiRequest({
      method: "POST",
      endpoint: "/logout",
      onSuccess: () => {
        setUser({ isLoggedIn: false });
        window.location.href = "/login";
      },
      hideSuccessSnackbar: true,
    });
  };

  return (
    <NavBarContainer>
      <div
        className="all the way left"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start", // Align items to the start
          flex: 1, // Allow this section to take up available space
        }}
      >
        <LogoImage src="/logo.png" alt="Climate Atlas" />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <LogoTitle>Climate</LogoTitle>
          <LogoTitle style={{ marginTop: -6 }}>Atlas</LogoTitle>
        </div>
      </div>
      <PageTitle
        className="in the middle"
        style={{ flex: 1, textAlign: "center" }}
      >
        Climate Destination Index
      </PageTitle>
      <div
        className="all the way right"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end", // Align items to the end
          flex: 1, // Allow this section to take up available space
        }}
      >
        {!exploreDataPageCsa && (
          <Slider value={isCbsaLevel} onChange={toggleLevel} />
        )}
        {!user ? null : user.isLoggedIn ? (
          <UserSection
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {isHovered ? (
              <Button onClick={handleLogout}>Logout</Button>
            ) : (
              <p>{user?.user || ""}</p>
            )}
          </UserSection>
        ) : (
          <div>
            <Button onClick={handleLogin}>Log In With Google</Button>
          </div>
        )}
      </div>
    </NavBarContainer>
  );
}
