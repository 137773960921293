import React from "react";
import GradientBar from "./GradientBar";
import ScorePill from "./ScorePill";
import { CsaProperties, useFlatConfig } from "../store";
import { getQuintileLabel, scoreColor } from "../utils";
import useBenchmarkSelector from "./BenchmarkSelector";
import { SmallTitleNotBold, BodyTextLight, BodyTextBold } from "../text";

const ScoreCard = ({
  title,
  description,
  percentile,
  label,
  color,
  reference,
}: {
  title: string;
  description: string;
  percentile: number;
  label: string;
  color: string;
  reference: string;
}) => (
  <div className="mb-3">
    <SmallTitleNotBold>{title}</SmallTitleNotBold>
    <BodyTextLight>{description}</BodyTextLight>
    <div className="flex flex-col">
      <div className="flex flex-row items-center my-2">
        <ScorePill label={label} color={color} />
        <div className="ml-8">
          <BodyTextBold>{percentile}th</BodyTextBold>{" "}
          <BodyTextLight>percentile in </BodyTextLight>
          <BodyTextBold>{reference}</BodyTextBold>
        </div>
      </div>
      <GradientBar percentile={percentile} />
    </div>
  </div>
);

export function DefaultScoreCard({
  category,
  properties,
}: {
  category: string;
  properties: CsaProperties;
}) {
  const getScore = (category: string) => properties.scoreTiers[category] || 0;
  const [benchmark] = useBenchmarkSelector({ benchmarks: ["Nation"] });
  const config = useFlatConfig();
  return (
    <ScoreCard
      key={category}
      title={category}
      description={config[category]?.description || ""}
      percentile={Math.round(getScore(category) * 100)}
      label={getQuintileLabel(getScore(category))}
      color={scoreColor(getScore(category))}
      reference={benchmark}
    />
  );
}

export default ScoreCard;
