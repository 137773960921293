import { useRef } from "react";
import { create } from "zustand";
import mapboxgl from "mapbox-gl";

export type Metric = string;

export interface Polygon {
  type: "Polygon";
  coordinates: number[][][];
}

export interface MultiPolygon {
  type: "MultiPolygon";
  coordinates: number[][][][];
}

export type Geometry = Polygon | MultiPolygon;

export interface CenterZoom {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
}

export interface CsaProperties {
  csaCode: string;
  cbsaCode: string; // hack
  name: string;
  scores: {
    [key: Metric]: number;
  };
  scoreTiers: {
    [key: Metric]: number;
  };
}

export interface GeojsonFeature {
  type: "Feature";
  properties: CsaProperties;
  geometry: Geometry;
}

export interface GeojsonData {
  type: "FeatureCollection";
  features: Array<GeojsonFeature>;
}

interface _StoreState {
  geojsonData: GeojsonData | null;
  geojsonCbsaData: GeojsonData | null;
  currentCSAData: CsaProperties | null;
  domainTab: DomainTab;
  mapMetric: Metric;
  activeTab: Tab;
  exploreDataPageCsa: CsaProperties | null;
  config: OverallScoringConfig | null;
  mapRef: mapboxgl.Map | null;
  isCbsaLevel: boolean;
}

interface _StoreActions {
  setGeojsonData: (data: GeojsonData) => void;
  setGeojsonCbsaData: (data: GeojsonData) => void;
  setCurrentCSAData: (data: CsaProperties | null) => void;
  setDomainTab: (tab: DomainTab) => void;
  setMapMetric: (metric: Metric) => void;
  setActiveTab: (tab: Tab) => void;
  setExploreDataPage: (page: CsaProperties | null) => void;
  setConfig: (config: OverallScoringConfig) => void;
  setMapRef: (map: mapboxgl.Map | null) => void;
  toggleLevel: () => void;
}

export type StoreState = _StoreState & _StoreActions;

interface SubindicatorScoringConfig {
  level: "subindicator";
  name: Metric;
  weight: number;
  datapointKey: string;
}

export type ScoringConfigTier =
  | "overall"
  | "domain"
  | "category"
  | "subcategory"
  | "indicator"
  | "subindicator";

export interface BaseScoringConfig {
  level: ScoringConfigTier;
  name: Metric;
  weight: number;
  description: string;
  long_description: string | null;
}

interface SubindicatorScoringConfig extends BaseScoringConfig {
  level: "subindicator";
  datapointKey: string;
}

interface IndicatorScoringConfig extends BaseScoringConfig {
  level: "indicator";
  items: SubindicatorScoringConfig[];
}

interface SubcategoryScoringConfig extends BaseScoringConfig {
  level: "subcategory";
  items: IndicatorScoringConfig[];
}

export interface CategoryScoringConfig extends BaseScoringConfig {
  level: "category";
  items: SubcategoryScoringConfig[];
}

export interface DomainScoringConfig extends BaseScoringConfig {
  level: "category";
  items: CategoryScoringConfig[];
}

interface OverallScoringConfig extends BaseScoringConfig {
  level: "overall";
  items: DomainScoringConfig[];
}

export type Tab = "Explore" | "Inspect" | "Compare";
export const DomainTabValues = [
  "Overall",
  "Growth Potential",
  "Baseline Resilience",
  "Hazard Insulation",
  "Path to Sustainability",
] as const;

export type DomainTab = (typeof DomainTabValues)[number];

export const useStore = create<StoreState>((set) => ({
  geojsonData: null,
  geojsonCbsaData: null,
  currentCSAData: null,
  domainTab: "Overall",
  mapMetric: "Overall",
  activeTab: "Explore",
  exploreDataPageCsa: null,
  config: null,
  mapRef: null,
  isCbsaLevel: true,
  setGeojsonData: (data: GeojsonData) => set({ geojsonData: data }),
  setGeojsonCbsaData: (data: GeojsonData) => set({ geojsonCbsaData: data }),
  setCurrentCSAData: (data: CsaProperties | null) =>
    set({ currentCSAData: data }),
  setDomainTab: (tab: DomainTab) => set({ domainTab: tab }),
  setMapMetric: (metric: Metric) => set({ mapMetric: metric }),
  setActiveTab: (tab: Tab) => set({ activeTab: tab }),
  setExploreDataPage: (page: CsaProperties | null) =>
    set({ exploreDataPageCsa: page }),
  setConfig: (config: OverallScoringConfig) => set({ config: config }),
  setMapRef: (map: mapboxgl.Map | null) => set({ mapRef: map }),
  toggleLevel: () => set((state) => ({ isCbsaLevel: !state.isCbsaLevel })),
}));

export function useFlatConfig() {
  const config = useStore((state) => state.config);
  const memoized = useRef<{
    lastConfig: OverallScoringConfig | null;
    lastFlatConfig: { [key: string]: BaseScoringConfig };
  }>({
    lastConfig: null,
    lastFlatConfig: {},
  });

  const getBase = ({
    level,
    name,
    weight,
    description,
    long_description,
  }: BaseScoringConfig): BaseScoringConfig => ({
    level,
    name,
    weight,
    description,
    long_description,
  });

  if (config !== memoized.current.lastConfig) {
    memoized.current.lastConfig = config;
    if (!config) {
      memoized.current.lastFlatConfig = {};
    } else {
      const flatConfig: Array<BaseScoringConfig> = config.items.flatMap(
        (category) => [
          getBase(category),
          ...category.items.flatMap((subcategory) => [
            getBase(subcategory),
            ...subcategory.items.flatMap((indicator) => [
              getBase(indicator),
              ...indicator.items.map((subindicator) => getBase(subindicator)),
            ]),
          ]),
        ]
      );
      memoized.current.lastFlatConfig = Object.fromEntries(
        flatConfig.map((subcategory) => [subcategory.name, subcategory])
      );
    }
  }

  return memoized.current.lastFlatConfig;
}
