import React from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { Tab, useStore, useFlatConfig } from "../store";
import TitleBox from "./TitleBox";
import Explore from "./Explore";
import { scoreColor } from "../utils";
import { darken } from "polished";
import Histogram from "./Histogram";

const SidePanelContainer = styled.div`
  background-color: white;
  border-right: 1px solid grey;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  height: calc(100vh - 60px);
`;

const TabNavigation = styled.div`
  border-bottom: 1px solid;
  display: flex;
  justify-content: space-between;
`;

const TabButton = styled.button<{ isActive: boolean }>`
  padding: 0.25rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  font-family: "Barlow", sans-serif;
  border-bottom-width: 2px;
  border-color: ${(props) => (props.isActive ? "black" : "transparent")};
  color: ${(props) => (props.isActive ? "black" : "#717171")};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

const ContentArea = styled.div`
  padding: 1rem;
`;

const HistogramContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledButton = styled.button<{ color: string; disabled: boolean }>`
  background-color: ${(props) => (props.disabled ? "#ccc" : props.color)};
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 200px;

  &:hover {
    background-color: ${(props) => darken(0.1, props.color)};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
`;

export default function SidePanel() {
  const navigate = useNavigate();
  const { currentCSAData, activeTab, setActiveTab, mapMetric } = useStore(
    (state) => ({
      currentCSAData: state.currentCSAData,
      activeTab: state.activeTab,
      setActiveTab: state.setActiveTab,
      mapMetric: state.mapMetric,
    })
  );
  const csaData = currentCSAData || null;

  const tabs: Tab[] = ["Explore", "Inspect", "Compare"];
  const csaSelected = !!csaData?.scores?.overall;
  const flatConfig = useFlatConfig();
  const mapMetricLevel = flatConfig[mapMetric]?.level;
  const exploreUrl = `/explore/csa/${csaData?.csaCode}`;
  const handleExploreData = () => {
    if (csaData) {
      navigate(exploreUrl);
    }
  };
  const key = mapMetric === "Overall" ? "overall" : mapMetric;
  const color = scoreColor(currentCSAData?.scoreTiers[key] || 0.5);

  const isExploring = useLocation().pathname.includes(exploreUrl);

  return (
    <SidePanelContainer>
      <TabNavigation>
        {tabs.map((tab) => (
          <TabButton
            key={tab}
            onClick={() => setActiveTab(tab)}
            isActive={activeTab === tab}
          >
            {tab}
          </TabButton>
        ))}
      </TabNavigation>
      <div>
        <TitleBox />
      </div>
      <ContentArea>
        {activeTab === "Compare" ? <p>Coming Soon</p> : <Explore />}
      </ContentArea>
      <HistogramContainer>
        {["Explore", "Inspect"].includes(activeTab) && (
          <Histogram
            numBuckets={mapMetricLevel === "subindicator" ? 2000 : 50}
          />
        )}
      </HistogramContainer>
      {csaSelected && (
        <ButtonContainer>
          <StyledButton
            color={color}
            onClick={handleExploreData}
            disabled={isExploring}
          >
            Explore Data
          </StyledButton>
        </ButtonContainer>
      )}
      <br />
    </SidePanelContainer>
  );
}
