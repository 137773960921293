import React from "react";
import styled from "styled-components";
import { useStore } from "../store";
import { Title, BodyText } from "../text";

const Container = styled.div`
  background-color: #e0e0e0;
  padding: 2rem;
  padding-bottom: 3rem;
  border-radius: 0.5rem;
  width: 100%;
`;

const Description = styled.p`
  color: #4a4a4a;
  margin-bottom: 2rem;
`;

const ScoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Score = styled.span`
  font-size: 1.7rem;
  font-weight: 700;
  color: #a00000;
`;

const Divider = styled.div`
  position: relative;
  height: 2px;
  background-color: black;
  flex-grow: 1;
  margin: 0 1rem;
`;

const Marker = styled.div`
  position: absolute;
  bottom: -5px;
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  transform: translateX(-50%);
`;

const Text = styled(BodyText)`
  position: absolute;
  font-weight: 600;
  text-align: center;
  display: block;
  margin-top: 0.5rem;
  transform: translateX(-50%);
`;

const CoreScore = () => {
  const { exploreDataPageCsa } = useStore((state) => ({
    exploreDataPageCsa: state.exploreDataPageCsa,
  }));
  const score = exploreDataPageCsa?.scoreTiers?.overall;
  const name = exploreDataPageCsa?.name;
  if (!score) {
    return null;
  }
  const scorePct = (score * 100).toFixed(0);
  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Title>Core Score</Title>
        <Score>{scorePct} / 100</Score>
      </div>
      <Description>
        Index combining data related to climate risk exposure, baseline
        resilience, and growth potential.
      </Description>
      <ScoreContainer>
        <span>0</span>
        <Divider>
          <Marker style={{ left: `${scorePct}%` }} />
          <Text style={{ left: `${scorePct}%` }}>{name}</Text>
        </Divider>
        <span>100</span>
      </ScoreContainer>
    </Container>
  );
};

export default CoreScore;
