import React from "react";
import { scoreColor } from "../utils";

const GradientBar = ({ percentile }: { percentile: number }) => {
  const gradientColors = [
    scoreColor(0),
    scoreColor(0.25),
    scoreColor(0.5),
    scoreColor(0.75),
    scoreColor(1),
  ];

  return (
    <div
      className="relative"
      style={{
        background: `linear-gradient(to right, ${gradientColors.join(", ")})`,
        height: "14px",
      }}
    >
      <div className="absolute h-full" style={{ left: `${percentile}%` }}>
        <div
          style={{
            height: "100%",
            width: "1.5px",
            backgroundColor: "black",
            transform: "translateX(-50%)",
          }}
        />
      </div>
    </div>
  );
};

export default GradientBar;
